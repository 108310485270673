import React from 'react';
import PropTypes from 'prop-types';
import { usePrefs, useLabels } from 'wsm-common-data';

const Button = ({ onClick }) => {
	const { buttonStyle, buttonSize, buttonText, enableButton } = usePrefs();
	const labels = useLabels();
	const buttonLabel = labels.get(buttonText);

	if (enableButton === 'false') {
		return null;
	}

	return (
		<div // eslint-disable-line jsx-a11y/click-events-have-key-events
			role="button"
			tabIndex="0"
			className={`btn mt-3 mb-5 ${buttonStyle} ${buttonSize}`}
			onClick={(e) => onClick(e, 0)}
		>
			{buttonLabel}
		</div>
	);
};

Button.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default Button;
