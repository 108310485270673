import React from 'react';
import PropTypes from 'prop-types';

const ItemInfo = ({ description, title }) => {
	if (description || title) {
		return (
			<div className="pt-3 pr-2 pb-1 text-left">
				{title && (
					<div className="bg-white ddc-font-size-xlarge font-weight-bold">
						{title}
					</div>
				)}
				{description && (
					<div
						className="bg-white ddc-font-size-large"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
			</div>
		);
	} else {
		return null;
	}
};

ItemInfo.defaultProps = {
	description: '',
	title: ''
};

ItemInfo.propTypes = {
	description: PropTypes.string,
	title: PropTypes.string
};

export default ItemInfo;
