import React from 'react';
import { usePrefs } from 'wsm-common-data';
import Gallery from '../components/Gallery';
import Title from '../components/Title';
import Description from '../components/Description';
import './Widget.scss';

const setStyles = (backgroundImageURL, backgroundColor) => {
	const styles = {};
	const backgroundGradient =
		'115deg, transparent 0%, transparent 60%, rgba(255,255,255,.1) 60%, rgba(255,255,255,.1) 100%';

	if (backgroundImageURL && backgroundImageURL !== 'BLANK') {
		styles.backgroundPosition = 'center center';
		styles.backgroundSize = 'cover';
		styles.backgroundImage = `url('${backgroundImageURL}')`;
		styles.backgroundRepeat = 'no-repeat';
	} else {
		if (backgroundColor && backgroundColor !== 'BLANK') {
			styles.background = backgroundColor;
		}
		styles.backgroundImage = `linear-gradient(${backgroundGradient})`;
	}

	return styles;
};

const Widget = () => {
	const { galleryType, backgroundImageURL, backgroundColor, textAlign } =
		usePrefs();

	// don't use 'hidden' class in case site's stylesheet makes 'hidden' things not display!
	let type = galleryType;
	if (galleryType === 'hidden') {
		type = 'hideGallery';
	}

	return (
		<div
			className={`background ${textAlign}`}
			style={setStyles(backgroundImageURL, backgroundColor)}
		>
			<Title />
			<Description />
			<Gallery galleryType={type} />
		</div>
	);
};

export default Widget;
