import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';

/**
 * creates gallery of image previews from the set of images used in photoswipe
 *
 * @param gallerySize - amount of images to display in preview gallery
 * @param mediaSet - set of itemList media
 * @param onClickFn - callback to act on a thumbnail click
 * @param maxSize - optional cap to length of object returned. used in Gallery component
 * @param thumbnailImageWidth - optional override width for the thumbnail images
 * @param thumbnailSpacing - choose how much spacing is between the thumbnail images
 * @param hoverEffect - set hover state for each photo thumbnail
 * @param windowId - sets the unique identifier used for keys of the thumbnail components, also used for tracking and tagging
 * @returns {*}
 */

const CreateThumbnails = (
	gallerySize,
	mediaSet,
	onClickFn,
	maxSize,
	thumbnailImageWidth,
	thumbnailSpacing,
	hoverEffect,
	widgetIntention,
	windowId
) => {
	let len = gallerySize;

	if (maxSize) {
		len = Math.min(gallerySize, maxSize);
	}

	let thumbnailSpacingClass = 'm-0';

	switch (thumbnailSpacing) {
		case '2':
			thumbnailSpacingClass = 'm-1';
			break;
		case '4':
			thumbnailSpacingClass = 'm-2';
			break;
		case '6':
			thumbnailSpacingClass = 'm-3';
			break;
		case '8':
			thumbnailSpacingClass = 'm-4';
			break;
		case '10':
			thumbnailSpacingClass = 'm-5';
			break;
		default:
			break;
	}

	// create gallery object equal to length of gallerySize or maxSize, whichever comes first
	const gallery = mediaSet.slice(0, len).map((media, i) => {
		const imageResize = thumbnailImageWidth || '410';
		const imageResizePolicy = `?impolicy=downsize_bkpt&w=${imageResize}`;
		const htmlregex = new RegExp(/(?:src=(.+)\b )/g);

		const { src, html } = media;
		const source = html
			? htmlregex.exec(html)[1]
			: `${src}${imageResizePolicy}`;

		return (
			<Thumbnail
				key={`${windowId}:Gallery:Thumbails${i.toString()}`}
				className=""
				id={i}
				thumbnailSpacingClass={thumbnailSpacingClass}
				media={media}
				source={source}
				onClickFn={onClickFn}
				hoverEffect={hoverEffect}
				widgetIntention={widgetIntention}
				windowId={windowId}
			/>
		);
	});

	return gallery;
};

const Thumbnail = ({
	id,
	thumbnailSpacingClass,
	media,
	source,
	onClickFn,
	hoverEffect,
	widgetIntention,
	windowId
}) => {
	return (
		<a
			href={source}
			className={`bg-white overflow-hidden ${media.classes} ${media.type} ${thumbnailSpacingClass}`}
			onClick={(e) => {
				e.stopPropagation();
				onClickFn(e, id);
				trackEvent('ws-media-viewer', windowId, {
					element: e.target.parentNode.parentNode,
					result: 'Opens gallery modal',
					dataToExcludeFromWidgetUserEvent: {
						index: id,
						widgetIntention
					}
				});
			}}
		>
			{media.type !== 'video' ? ( // use img tag for images, video for videos
				<img
					className={`h-100 ${hoverEffect}`}
					src={source}
					alt={media.alt}
				/>
			) : (
				// linter disabled here because videos uploaded will not have captions
				// eslint-disable-next-line jsx-a11y/media-has-caption
				<video>
					<source src={source} type="video/mp4" />
				</video>
			)}
		</a>
	);
};

Thumbnail.propTypes = {
	hoverEffect: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	thumbnailSpacingClass: PropTypes.string.isRequired,
	onClickFn: PropTypes.func.isRequired,
	source: PropTypes.string.isRequired,
	media: PropTypes.shape({
		type: PropTypes.string,
		alt: PropTypes.string,
		classes: PropTypes.string
	}).isRequired,
	widgetIntention: PropTypes.string,
	windowId: PropTypes.string.isRequired
};

export default CreateThumbnails;
