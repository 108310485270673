import React from 'react';
import { usePrefs, useLabels } from 'wsm-common-data';

const Title = () => {
	const { title, titleHeadingSize, enableTitle } = usePrefs();
	const labels = useLabels();

	if (enableTitle === 'false' || title === 'BLANK') {
		return null;
	}

	const titleLabel = labels.get(title);

	return (
		<h1 className={`mt-0 pt-5 pb-3 ${titleHeadingSize}`}>{titleLabel}</h1>
	);
};

export default Title;
