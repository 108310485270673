/** TODO
 * What is this file? This is a component from the abandoned npm library 'react-photoswipe'
 * (see here: https://github.com/minhtranite/react-photoswipe)
 *
 * Because the library is abandoned/broken, and will not build when installed, we opted to
 * copy the component we needed and refactor for ES6 and our linter. We also added functionality
 * so that images of unknown sizes can be used with photoswipe. For now, it will live here.
 * When possible, it would be a good idea to move this file and the rest of the react-photoswipe
 * library out of this widget once we have the capability to build our own modules for consumption
 * in OSIRIS, so that it can be maintained by us and used elsewhere within our system.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Photoswipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import { trackEvent } from 'ddc-track-event';
import PhotoswipeThumbnails from './PhotoswipeThumbnails';
import events from './events';
import ItemInfo from './ItemInfo';

export default class ReactPhotoSwipe extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: this.props.isOpen,
			currentIndex: this.props.options.index
		};

		this.goToPhoto = this.goToPhoto.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	componentDidMount() {
		// Todo give each photoswipe instance unique ID so that history can be used via unique URLS
		const { isOpen } = this.state;
		// Disabling history because if a page has multiple galleries and a url hash is present then
		// both instances of photoswipe will open to the item ID. The galleries need unique UIDs for this
		// to work properly. For now, history is set to false in options (Gallery.jsx).
		// See https://photoswipe.com/documentation/options.html (history, galleryUID).
		if (isOpen) {
			// || window.location.hash) {
			this.openPhotoSwipe(this.props);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isOpen !== this.props.isOpen) {
			if (!prevProps.isOpen) {
				this.openPhotoSwipe(this.props);
			} else if (prevProps.isOpen) {
				this.closePhotoSwipe();
			}
		}
	}

	componentWillUnmount() {
		this.closePhotoSwipe();
	}

	handleClose() {
		const { onClose } = this.props;
		this.setState(
			{
				isOpen: false
			},
			() => {
				if (onClose) {
					onClose();
				}
			}
		);
	}

	updateIndex() {
		this.setState({
			currentIndex: this.photoSwipe.getCurrentIndex()
		});
	}

	goToPhoto(e, i) {
		if (e) {
			e.preventDefault();
		}
		this.state = {
			currentIndex: i
		};
		this.photoSwipe.goTo(i);
	}

	openPhotoSwipe(props) {
		const { items, options } = props;
		const { pswpElement } = this;
		this.photoSwipe = new Photoswipe(
			pswpElement,
			PhotoswipeUIDefault,
			items,
			options
		);
		events.forEach((event) => {
			const callback = props[event];
			if (callback || event === 'destroy' || event === 'afterChange') {
				const self = this;
				this.photoSwipe.listen(event, (...args) => {
					if (callback) {
						args.unshift(this);
						callback(...args);
					}
					if (event === 'afterChange') {
						self.updateIndex();
					}
					if (event === 'destroy') {
						self.handleClose();
					}
				});
			}
		});

		// Serving images of unknown size; pass 0 to w and h
		const gallery = this.photoSwipe;
		gallery.listen('gettingData', (index, item) => {
			if (item.html === undefined && (item.w < 1 || item.h < 1)) {
				// unknown size
				const img = new Image();
				img.onload = function getSize() {
					// will get size after load
					// need to allow assignment to fn parameter item in order to set dimensions
					// eslint-disable-next-line no-param-reassign
					item.w = this.width; // set image width
					// eslint-disable-next-line no-param-reassign
					item.h = this.height; // set image height
					gallery.invalidateCurrItems(); // reinit Items
					gallery.updateSize(true); // reinit Items
				};
				img.src = item.src; // let's download image
			}
		});
		this.photoSwipe = gallery;
		this.setState(
			{
				isOpen: true
			},
			() => {
				this.photoSwipe.init();
			}
		);
	}

	updateItems(items = []) {
		this.photoSwipe.items.length = 0;
		items.forEach((item) => {
			this.photoSwipe.items.push(item);
		});
		this.photoSwipe.invalidateCurrItems();
		this.photoSwipe.updateSize(true);
	}

	closePhotoSwipe() {
		if (!this.photoSwipe) {
			return;
		}
		this.photoSwipe.close();
	}

	render() {
		const { id, items, showLightboxThumbnails, windowId, widgetIntention } =
			this.props;
		let { className } = this.props;
		className = `pswp ${className}`;
		const itemDescription = items[this.state.currentIndex].description;
		const itemTitle = items[this.state.currentIndex].titleItem;

		// This markup is required for photoswipe to work. I would recommend not changing it.
		// Styles here are currently divided between photoswipe.scss in ws-media-viewer repo and cms-web
		// DDC-WebPlatform/cms-web/blob/master/htdocs/v9/media/js/photoswipe/v4.0.8/src/css/main.scss
		// https://photoswipe.com/documentation/getting-started.html

		return (
			<div
				id={id}
				className={`h-100 ${className}`}
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
				ref={(node) => {
					this.pswpElement = node;
				}}
			>
				<div className="pswp__bg" />
				<div className="pswp__scroll-wrap">
					<div className="pswp__container">
						<div className="pswp__item text-center" />
						<div className="pswp__item text-center" />
						<div className="pswp__item text-center" />
					</div>
					<div className="pswp__ui pswp__ui--hidden">
						<div className="pswp__top-bar">
							<div className="pswp__counter" />
							<button
								className="pswp__button pswp__button--close"
								title="Close (Esc)"
								aria-label="Close (Esc)"
								type="button"
							/>
							<button
								className="pswp__button pswp__button--share"
								title="Share"
								aria-label="Share"
								type="button"
							/>
							<button
								className="pswp__button pswp__button--fs"
								title="Toggle fullscreen"
								aria-label="Toggle fullscreen"
								type="button"
							/>
							<button
								className="pswp__button pswp__button--zoom"
								title="Zoom in/out"
								aria-label="Zoom in/out"
								type="button"
							/>
							<div className="pswp__preloader">
								<div className="pswp__preloader__icn">
									<div className="pswp__preloader__cut">
										<div className="pswp__preloader__donut" />
									</div>
								</div>
							</div>
						</div>
						<div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
							<div className="pswp__share-tooltip" />
						</div>
						<button
							className="pswp__button pswp__button--arrow--left"
							title="Previous (arrow left)"
							aria-label="Previous (arrow left)"
							type="button"
							onClick={() => {
								trackEvent('ws-media-viewer', windowId, {
									action: 'clicked',
									dataToExcludeFromWidgetUserEvent: {
										widgetIntention
									},
									element: 'Scroll Arrow',
									result: 'Scrolled to previous photo',
									widgetState: 'Gallery Modal'
								});
							}}
						/>
						<button
							className="pswp__button pswp__button--arrow--right"
							title="Next (arrow right)"
							aria-label="Next (arrow right)"
							type="button"
							onClick={() => {
								trackEvent('ws-media-viewer', windowId, {
									action: 'clicked',
									dataToExcludeFromWidgetUserEvent: {
										widgetIntention
									},
									element: 'Scroll Arrow',
									result: 'Scrolled to next photo',
									widgetState: 'Gallery Modal'
								});
							}}
						/>
						<div className="pswp__caption">
							<div className="pswp__caption__center" />
						</div>
					</div>
				</div>
				{showLightboxThumbnails && (
					<div // eslint-disable-line jsx-a11y/click-events-have-key-events
						role="button"
						tabIndex="-1"
						className="bg-dark w-100 pt-4 fixed-bottom d-flex justify-content-center"
						onClick={this.handleClose}
					>
						<ItemInfo
							title={itemTitle}
							description={itemDescription}
						/>
						<PhotoswipeThumbnails
							items={items}
							currentIndex={this.state.currentIndex}
							callback={this.goToPhoto}
							identifier={this.props.identifier}
						/>
					</div>
				)}
			</div>
		);
	}
}

ReactPhotoSwipe.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			titleItem: PropTypes.string,
			description: PropTypes.string
		})
	).isRequired,
	/* eslint react/no-unused-prop-types: 0 */
	options: PropTypes.shape({
		index: PropTypes.number.isRequired
	}),
	onClose: PropTypes.func,
	id: PropTypes.string,
	className: PropTypes.string,
	showLightboxThumbnails: PropTypes.bool.isRequired,
	identifier: PropTypes.string.isRequired,
	windowId: PropTypes.string.isRequired,
	widgetIntention: PropTypes.string
};

ReactPhotoSwipe.defaultProps = {
	options: {},
	onClose: () => {},
	id: '',
	className: '',
	widgetIntention: ''
};
