/**
 * Sorts itemlist based on 'featured' member and replaces each of the label ids for each
 * `title` and `description` in the itemlist with the corresponding label text.
 *
 * @param items
 * @param labels
 */
export const formatItemlist = (items, labels) => {
	// sort based items that have featured set to true to the front of the list
	items.sort((a, b) => {
		return b.featured - a.featured;
	});
	// set labels
	return items.map((i) => {
		const item = { ...i };
		item.title = labels.get(item.title);
		item.description = labels.get(item.description);
		return item;
	});
};

/**
 * returns an object from the itemList that is passed to ReactPhotoswipe for use with the Photoswipe Lightbox.
 * The returned 'title' property is used by photoswipe to display both title and description when the lightbox is without thumbnails.
 * The 'description' and 'titleItem' properties are used by the ItemInfo component to show title and description in the vertical thumbnail view.
 * @param items - array of image objects
 * @returns {*}
 */
export const getPhotoswipeObject = (items) => {
	return items.map((item) => {
		let altTag = 'photo';
		let titleMemberCombined = '';
		let descriptionMember;
		let titleMember;

		if (item.title) {
			titleMemberCombined = titleMemberCombined.concat(item.title);
			altTag = item.title;
			titleMember = item.title;
		}
		if (item.description) {
			titleMemberCombined = titleMemberCombined.concat(
				`<br><small>${item.description}</small>`
			);
			descriptionMember = item.description;
		}

		return {
			...(item.type !== 'video' && { src: item.uri }),
			title: titleMemberCombined,
			description: descriptionMember,
			titleItem: titleMember,
			w: 0,
			h: 0,
			classes: `preview ${item.style}`,
			type: item.type,
			alt: altTag,
			...(item.type === 'video' && {
				html: `<video controls><source src=${item.uri} type="video/mp4" /></video>`
			})
		};
	});
};
