import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import CreateThumbnails from './CreateThumbnails';

const PhotoswipeThumbnails = ({
	items,
	callback,
	currentIndex,
	identifier,
	widgetIntention
}) => {
	const thumbs = CreateThumbnails(
		items.length,
		items,
		callback,
		undefined,
		undefined,
		undefined,
		undefined,
		widgetIntention,
		identifier
	);

	// ToDo allow for horizontal thumbnails via pref or automatically
	const orientation = 'vertical';
	// Creating bounds for which thumbnails to show based on the current index.
	// Always shows a maximum of 9, currentIndex will be in the center of the
	// array unless ci < 4 or ci > items.length - 4
	const lowerBound = Math.max(
		0,
		Math.min(items.length - 9, currentIndex - 4)
	);
	const upperBound = Math.min(items.length, Math.max(9, currentIndex + 5));

	// apply bounds to total array of thumbnails
	const slicedThumbs = thumbs.slice(lowerBound, upperBound);
	// Update each thumb object to have class of current when it corresponds to current index.
	const renderedThumbs = slicedThumbs.map((thumb) => {
		return {
			...thumb,
			props: {
				...thumb.props,
				className:
					parseInt(thumb.key, 10) === currentIndex
						? thumb.props.className.concat(' current')
						: thumb.props.className
			}
		};
	});
	return (
		<div className={`pswp_thumbnails ${orientation}`}>{renderedThumbs}</div>
	);
};

PhotoswipeThumbnails.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	callback: PropTypes.func.isRequired,
	currentIndex: PropTypes.number.isRequired,
	identifier: PropTypes.string.isRequired,
	widgetIntention: PropTypes.string
};

export default PhotoswipeThumbnails;
