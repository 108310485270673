import React from 'react';
import { usePrefs, useLabels } from 'wsm-common-data';

const Description = () => {
	const { description, enableDescription } = usePrefs();
	const labels = useLabels();

	if (enableDescription === 'false' || description === 'BLANK') {
		return null;
	}

	const descriptionLabel = labels.get(description);

	return (
		<div role="article" className="mb-2">
			{descriptionLabel}
		</div>
	);
};

export default Description;
