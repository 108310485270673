import { SET_ITEMS } from '../actions/types';

export default function items(state = [], action) {
	if (!action.error) {
		switch (action.type) {
			case SET_ITEMS: {
				return [...state, ...action.payload];
			}
			// no default
		}
	}

	return state;
}
