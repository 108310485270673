import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';

import { getPhotoswipeObject, formatItemlist } from '../utils/galleryUtils';
import Button from './Button.jsx';

import ReactPhotoSwipe from './react-photoswipe/ReactPhotoSwipe';
import CreateThumbnails from './react-photoswipe/CreateThumbnails';

const GALLERY_MAX_LENGTH = 16;

const Gallery = ({ galleryType }) => {
	const { windowId, deviceType } = useRequestData();
	const { items } = useSelector((state) => state);
	const {
		buttonSize,
		buttonStyle,
		buttonText,
		closeLightboxOnBackground,
		galleryImageSize,
		gallerySize,
		gridItemsPerRow,
		showLightboxImageCount,
		thumbnailImageWidth,
		thumbnailSpacing,
		hoverEffect,
		showLightboxThumbnails,
		widgetIntention
	} = usePrefs();

	const labels = useLabels();
	const [isOpen, setIsOpen] = useState(false);
	const [options, setOptions] = useState({
		index: 0,
		tapToClose: closeLightboxOnBackground,
		showHideOpacity: true,
		getThumbBoundsFn: false,
		counterEl: showLightboxImageCount,
		history: false
	});

	const handleClose = () => {
		setIsOpen(false);
	};

	const openPhotoSwipe = (e, i) => {
		e.preventDefault();
		options.index = i;
		setIsOpen(true);
		setOptions({
			...options,
			index: i
		});
	};

	const shouldRenderGallery = items && items.length > 0;

	// Sorts itemlist by featured and updates titles and descriptions of each item
	const itemlist = formatItemlist(items, labels);
	// Creates object from the itemList that can be passed to Photoswipe
	const psSet = getPhotoswipeObject(itemlist);
	// Show thumbnails when preference is true and user is on desktop
	const showThumbs =
		showLightboxThumbnails === 'true' && deviceType === 'DESKTOP';

	let rpsClassNames = '';
	if (showThumbs) {
		rpsClassNames = 'thumbnails';
	}
	if (shouldRenderGallery) {
		return (
			<div className="main-gallery">
				<div
					role="row"
					className={`gallery ${galleryType} ${galleryImageSize} row-items-${gridItemsPerRow}`}
				>
					<Button
						buttonStyle={buttonStyle}
						buttonSize={buttonSize}
						buttonText={labels.get(buttonText)}
						onClick={openPhotoSwipe}
					/>
					{galleryType !== 'hideGallery' && (
						<div className={`layout ${galleryType}`}>
							{CreateThumbnails(
								gallerySize,
								psSet,
								openPhotoSwipe,
								GALLERY_MAX_LENGTH,
								thumbnailImageWidth,
								thumbnailSpacing,
								hoverEffect,
								widgetIntention,
								windowId
							)}
						</div>
					)}
				</div>
				<ReactPhotoSwipe
					isOpen={isOpen}
					items={psSet}
					options={options}
					onClose={handleClose}
					className={rpsClassNames}
					showLightboxThumbnails={showThumbs}
					identifier={`${windowId}:Photoswipe:Thumbails`}
					widgetIntention={widgetIntention}
					windowId={windowId}
				/>
				{isOpen && (
					/* disabling eslint, unsure if making this more accessible with a key event is feasible */
					<div // eslint-disable-line jsx-a11y/click-events-have-key-events
						role="button"
						className="fixed-top fixed-left w-100 min-vh-100 bg-dark zindex-tooltip"
						onClick={handleClose}
						tabIndex="-1"
						aria-label="Close"
					/>
				)}
			</div>
		);
	}
	// this will only return in composer so that the editor can be opened.
	// if there are no items the widget will return a 204 live
	return (
		<div className="ddc-heading-5">
			No images have been added to the gallery.
		</div>
	);
};

Gallery.propTypes = {
	galleryType: PropTypes.string.isRequired
};

export default Gallery;
